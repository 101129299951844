.container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  color: white;
}
.header {
  position: absolute;
  top: 0px;
}
.body {
  display: flex;
  flex-direction: column;
  width: 220px;
}
